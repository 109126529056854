<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 28%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="查询时段" prop="queryInstallDate" style="width: 28%">
              <a-range-picker v-model="queryInstallDate" :default-value="[moment(`${queryParams.starttime.substring(0,10)}`, 'YYYY-MM-DD'), moment(`${queryParams.endtime.substring(0,10)}`, 'YYYY-MM-DD')]"></a-range-picker>
            </a-form-model-item>
            <a-form-model-item label="类型" prop="systemnameid" style="width: 15%">
              <a-select v-model.trim="queryParams.systemnameid" placeholder="请输入">
                <a-select-option :value="0">全部</a-select-option>
                <a-select-option :value="1">消防</a-select-option>
                <a-select-option :value="2">其它</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button type="primary" @click="getEventTimeRange(true)">当年</a-button>
              <a-button class="after-btn" @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getEventTimeRange()">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">确警时长</h3>
        </div>
        <div class="pie" id="pie" style="height: 700px;width: 100%"></div>
      </div>
    </div>
  </page-container>
</template>

<script>
import moment from 'moment'
import {mapState} from "vuex";
import {getTimeRange} from "U/index";
import * as echarts from "echarts";
import {getAlarmEventTimeRange, getEventTimeRange} from "A/wlw";
import deptselect from "@/mixins/deptselect";

export default {
  name: "alarmProcessTime",
  mixins: [deptselect],
  data() {
    return {
      moment,
      dataList: [],
      queryInstallDate: null,
      userdepidCascaderSelected: [],
      queryParams: {
        monitorpointname: '',
        starttime: '',
        endtime: '',
        systemnameid:0,
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return "确警时长"
    },
    breadcrumb() {
      const pages = [{name:"数据分析与报告",path:""}]
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    let time = new Date();
    let monthNum = moment(time).format("YYYY-MM").slice(5);
    this.queryParams.starttime = moment(time).month(monthNum - 1).date(1).startOf("month").format("YYYYMMDD");
    this.queryParams.endtime = moment(time).month(monthNum - 1).date(1).endOf("month").format("YYYYMMDD");
    this.getEventTimeRange()
    this.initDeptOptionsAll();
  },
  methods: {
    resetQueryParams() {
      this.queryInstallDate = [];
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    getEventTimeRange(flag){
      if(flag){
        this.queryParams.starttime=moment(new Date()).format("YYYY")+"0101"
        this.queryParams.endtime=moment(new Date()).format("YYYY")+"1231"
      }
      let params = {
        ...this.queryParams
      }
      getAlarmEventTimeRange(params).then(res=>{
        if(res&&res.returncode=='0'){
          this.dataList=[]
          if(res.eventList[0].threeminute!=0){this.dataList.push({value:res.eventList[0].threeminute,name:"≤3分钟"})}
          if(res.eventList[0].threetofive!=0){this.dataList.push({value:res.eventList[0].threetofive,name:">3分钟~≤5分钟"})}
          if(res.eventList[0].upfiveminute!=0){this.dataList.push({value:res.eventList[0].upfiveminute,name:">5分钟"})}
          let that=this
          setTimeout(()=>{
            that.initCharts()
          },500)
        }
      })

    },
    initCharts(){
      let chartDom = document.getElementById('pie');
      let myChart = echarts.init(chartDom);
      let option;
      option = {
        title: {
          text: '确警时长分析',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: '确警时长比例',
            type: 'pie',
            radius: '70%',
            // label : {
            //   normal : {
            //     formatter: '{b}:{c} ({d}%)',
            //     textStyle : {
            //       fontWeight : 'normal',
            //       fontSize : 15
            //     }
            //   }
            // },
            data: this.dataList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      option && myChart.setOption(option);
    }
  }
}
</script>

<style scoped>
.pie{
  pointer-events: auto;
}
</style>